import React, { useState } from 'react'
// Modules
import { useForm } from 'react-hook-form'
// MUI Core
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { setUser } from '../services/auth'
import { navigate } from 'gatsby'
import { API_URL } from '../configs/events-api'
import Layout from '../components/layout'
import SEO from '../components/seo'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    height: '50vh',
  },
}))

const LoginPage = () => {
  const [error, setError] = useState('')
  const { handleSubmit, register, errors } = useForm()

  const classes = useStyles()

  const onSubmit = handleSubmit(data => {
    fetch(`${API_URL}/auth/login`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        return Promise.reject(response)
      })
      .then(data => {
        if (data.user) {
          setUser({ ...data.user, token: data.token })
          navigate('/')
        }
      })
      .catch(e => {
        setError(`Login failed (${e.status})`)
      })
  })

  return (
    <Layout>
      <SEO title="Log in to Leafbook" />
      <Container className={classes.container} maxWidth="xs">
        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">Login</Typography>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography color="error" variant="caption">
                  {error}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={errors.email}
                    fullWidth
                    inputRef={register({
                      required: true,
                      pattern: /[a-z]+@[a-z]{2,13}\.[a-z]{2,3}/,
                    })}
                    label="Email"
                    name="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={errors.password}
                    inputRef={register({ required: true })}
                    label="Password"
                    name="password"
                    type="password"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
              >
                Log in
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Layout>
  )
}

export default LoginPage
